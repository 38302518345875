import { Box, Heading, Text, Button, Flex } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import {ReactTyped} from "react-typed";

import bgImage from "../../images/main_bg.png";

const Hero = () => (
  <Flex alignItems={"center"} p={4} justifyContent={"center"} width={'100%'} mt={4}>
  <Box
    id="hero-section"  // Adding id for future linking
    bgImage={bgImage}  // Add the background image
    bgSize="cover"     // Cover the area as per the content
    bgPosition="center"
    bgRepeat="no-repeat"
    maxW="1206px"
    width="100%" 
    borderRadius="16px"
    py={[10, 16]}      // Responsive padding (adjust for mobile)
    px={4}             // Add padding to prevent content from touching edges
  >
    <Flex 
      direction="column" 
      align="center" 
      mx="auto"
      width="100%"
    >
      {/* Box for RFX Processes */}
      <Box 
        bg="primary.500" 
        px={4} 
        py={1} 
        borderRadius="6px" 
        color="white" 
        mb={6} 
      >
        Automate Your RFX Processes
      </Box>

      {/* Heading */}
      <Heading
        size={["2xl", "3xl"]}   // Responsive font size for mobile
        color="gray.800"
        textAlign="center"
        mb={4}
      >
        AI Assistant <br /> for {" "}
        <ReactTyped
          strings={[
            "Presales Teams",
            "Sales Engineers",
            "Bid Managers",
            "Sales Consultants",
            "Revenue Leaders"
          ]}
          typeSpeed={90}       // Adjust typing speed
          backSpeed={40}       // Adjust backspacing speed
          backDelay={1500}
          loop                // Enables looping of text
          // fadeOut={true}
          showCursor={false}
          autoInsertCss={true}
        />
      </Heading>

      {/* Subheading */}
      <Text
        fontSize={["lg", "xl"]}  // Responsive font size
        color="gray.600"
        textAlign="center"
        maxW="800px"  // Constrain width for text wrapping
      >
        Spend less time creating proposals, answering questions, and more time building client relationships. 
          <Text as="span" color="secondary.500" fontWeight={"bold"} >{" "}Win clients, not just deals! </Text>
      </Text>

      {/* Call-to-Action Button */}
      <Button 
        colorScheme="primary" 
        width={"180px"}
        size="lg" 
        mt={8} 
        boxShadow="lg"
        rightIcon={<ChevronRightIcon />}
        onClick={() => {
          document.getElementById('contact-section').scrollIntoView({ behavior: 'smooth' });
        }}
      >
        Book a Demo
      </Button>
    </Flex>
  </Box>
  </Flex>
);

export default Hero;
