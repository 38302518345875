import { Box, Flex, Heading, Text, List, ListItem, ListIcon, Image } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import proposal from '../../images/proposal.png'

const AutomateSection = () => (
  <Flex
    id="automate-section"         // ID for linking or future reference
    mx="auto"                      // Center horizontally
    mt={{ base: 4, md: 8 }}
    py={{ base: 4, md: 8 }}                   // Reduced vertical padding for mobile
    px={{ base: 4, md: 0 }}
    direction={{ base: "column", md: "row" }}  // Stack on mobile, horizontal on desktop
    align={{ base: "center", md: "center" }}
    gap={{ base: 6, md: "48px" }}
  >
    {/* Left Side - 60% Width for Content */}
    <Box flex="0.55" pr={{ md: 8 }} textAlign={{ base: "center", md: "left" }}>
      {/* Heading */}
      <Heading size={{ base: "xl", md: "2xl" }}  color="gray.800" mb={4}>
        Automate, Learn, and Deliver High-Impact Proposals Faster
      </Heading>

      {/* Subheading */}
      <Text fontSize={{ base: "lg", md: "xl" }} color="gray.600" mb={6}>
        With Keats, create high quality proposals and respond to client's questions with AI generated content within minutes     </Text>

      {/* Bullet Points Section */}
      <List spacing={4}>
        <ListItem fontSize="lg" textAlign={"left"}>
          <ListIcon as={CheckCircleIcon} color="primary.500" />
          Keats learns from your knowledge base, past proposals and successes to create winning proposals
        </ListItem>
        <ListItem fontSize="lg" textAlign={"left"}>
          <ListIcon as={CheckCircleIcon} color="primary.500" />
          Understands your client's needs & context and customizes proposals accordingly
        </ListItem>
        <ListItem fontSize="lg" textAlign={"left"}>
          <ListIcon as={CheckCircleIcon} color="primary.500"  />
          Tracks conversations across Email, Slack, and Teams; refining proposals based on real-time discussions.
        </ListItem>
      </List>
    </Box>

    {/* Right Side - 40% Width for Image */}
    <Box flex="0.45" display="flex" justifyContent="center" alignItems="center">
      <Image 
        src={proposal}
        alt="Create proposals and respond to RFP automatically" 
        borderRadius="16px" 
        maxW="100%" 
      />
    </Box>
  </Flex>
);

export default AutomateSection;