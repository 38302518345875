import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './pages/Home';
import Privacy from './pages/Privacy';
// import Post from './pages/Post';
// import Overview from './pages/Overview';

import { ChakraProvider } from "@chakra-ui/react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google"
import theme from './theme';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "privacy",
    element: <Privacy />
  },
  // {
  //   path: "post",
  //   element: <Post />
  // }
]);

root.render(
  <GoogleOAuthProvider clientId='587631484069-hknj8hdlk9hfdt4iof8dtcb90kk11ts8.apps.googleusercontent.com'>
    <React.StrictMode>
      <ChakraProvider theme={theme}>
        <RouterProvider router={router} />
      </ChakraProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);