import { Box, Flex, Heading, Text, Center } from "@chakra-ui/react";

const benefits = [
  {
    percentage: "60%",
    title: "Time Saving",
    description: "Let your team focus on what they do best—solutioning, and engaging with clients",
  },
  {
    percentage: "42%",
    title: "More Proposals",
    description: "Streamline your presales team work and attempt more proposals with high winning probability ",
  },
  {
    percentage: "15%",
    title: "Higher Win Rate",
    description: "Create high-quality, tailored proposals, driving higher deal closures and boosting revenue",
  }
];

const BenefitSection = () => (
  <Box
    id="benefit-section"
    maxW="1206px"
    mx="auto"
    mt={{ base: 4, md: 8 }}
    py={{ base: 6, md: 10 }}
    px={4}
    textAlign="center"
  >
    {/* Section Heading and Subheading */}
    <Heading size={{ base: "xl", md: "2xl" }} color="gray.800" mb={4}>
        Why Choose Keats
    </Heading>
    <Text fontSize="lg" color="gray.600" mb={{ base: 6, md: 12 }}>
        Presales Teams Trust Keats to Deliver Faster and Better Results
    </Text>

    {/* Cards Container */}
    <Flex wrap="wrap" justify="space-around" gap={{ base: 2, md: 4 }}>
      {benefits.map((benefit, index) => (
        <Box
          key={index}
          width={{ base: "100%", md: "30%" }}  // Responsive width for mobile and desktop
          bg="white"
          borderRadius="16px"
          boxShadow="0px 12px 10px rgba(106, 106, 106, 0.12)"  // Custom shadow
          p={{ base: 2, md: 6 }}
          textAlign="center"
        >
          {/* Percentage Circle */}
          <Center 
            bg="primary.100" 
            borderRadius="full" 
            boxSize="100px" 
            mx="auto" 
            mb={4}
          >
            <Text fontSize="3xl" fontWeight="bold" color="primary.500">
              {benefit.percentage}
            </Text>
          </Center>

          {/* Card Title and Description */}
          <Heading size="lg" color="gray.800" mb={2}>
            {benefit.title}
          </Heading>
          <Text color="gray.600">
            {benefit.description}
          </Text>
        </Box>
      ))}
    </Flex>
  </Box>
);

export default BenefitSection;
