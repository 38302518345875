import { Box, Flex, Heading, Text, Divider, Icon, VStack, Image } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";  // You can replace this with any icon
import solution from '../../images/solution.png';

const PartnerSection = () => (
  <Flex
    id="partner-section"       // ID for linking or future reference
    maxW="1206px"              // Max width similar to header
    mx="auto" 
    mt={{ base: 4, md: 8 }}
    py={{ base: 4, md: 8 }}
    px={{ base: 4, md: 0 }}
    direction={{ base: "column-reverse", md: "row" }}
    align={{ base: "center", md: "center" }}
    gap={{ base: 6, md: "48px" }}
  >
    {/* Left Side - Static Image */}
    <Box flex="0.45" display={{ base: "none", md: "flex" }} justifyContent="center" alignItems="center">
      <Image 
        src={solution} 
        alt="Create RFP responses, proposals and respond to client questions faster" 
        borderRadius="16px" 
        maxW="100%" 
      />
    </Box>

    {/* Right Side - Text Content */}
    <Box flex="0.55" pr={{ md: 8 }} textAlign={{ base: "center", md: "left" }}>
      {/* Small box above heading */}
      <Box 
        bg="white" 
        border="1px solid" 
        borderColor="#E2E8F0" 
        px={4} 
        py={2} 
        mb={4} 
        fontWeight="bold"
        borderRadius="md"
        width="fit-content"
        mx={{ base: "auto", md: "0" }}
      >
        Automate Your RFX Process
      </Box>

      {/* Heading */}
      <Heading size={["xl", "2xl"]} color="gray.800" mb={4}>
        Your Partner in Every Step of the Presales Journey
      </Heading>

      {/* Subheading Paragraph */}
      <Text fontSize={["lg", "xl"]} color="gray.600" mb={4}>
        Keats empowers presales teams by automating the most critical tasks. From requirement evaluation to proposal creation, Keats helps win more deals with less effort.
      </Text>

      {/* Horizontal Divider */}
      <Divider borderColor="#E2E8F0" mb={6} />

      {/* Benefits Section */}
      <Flex direction={{ base: "column", md: "row" }} gap={6}>
        <VStack align="start" spacing={2} flex="1">
          <Flex align="center">
            <Icon as={CheckIcon} color="primary.500" mr={2} /> {/* Icon */}
            <Text fontWeight="bold" fontSize={["lg", "xl"]} color="gray.800">
              60% Less Time
            </Text>
          </Flex>
          <Text color="gray.600" fontSize={["md", "lg"]} textAlign={"left"}>
            Reduce manual work, allowing your team to focus on solutioning and client interactions.
          </Text>
        </VStack>

        <VStack align="start" spacing={2} flex="1">
          <Flex align="center">
            <Icon as={CheckIcon} color="primary.500" mr={2} /> {/* Icon */}
            <Text fontWeight="bold" fontSize={["lg", "xl"]} color="gray.800">
              15% More Deals
            </Text>
          </Flex>
          <Text color="gray.600" fontSize={["md", "lg"]} textAlign={"left"}>
            Create better quality solutions with saved time and AI assistance to win more deals.
          </Text>
        </VStack>
      </Flex>
      {/* Mobile-only Image Below Content */}
      <Box display={{ base: "flex", md: "none" }} justifyContent="center" alignItems="center" mt={4}>
        <Image 
          src={solution} 
          alt="Create RFP responses, proposals and respond to client questions faster" 
          borderRadius="16px" 
          maxW="100%" 
        />
      </Box>
    </Box>
  </Flex>
);

export default PartnerSection;
