import axios from "axios";

const baseURL = "https://a6w9hy4611.execute-api.ap-south-1.amazonaws.com/prod"

const api = axios.create({
  baseURL: baseURL,  // Use your base API URL
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;