import { Box, Flex, Text, Link, Divider, Image } from "@chakra-ui/react";
import logo from '../images/keatsai-128.png'

const Footer = () => (
  <Box bg="primary.500" color="white" py={2} px={6} width="100%" mt={4}>
    <Flex 
      maxW="1206px"       // Centered width limit
      mx="auto"           // Center horizontally
      direction={{ base: "column", md: "row" }}
      justify="space-between"
      gap={8}
      pt={8}
    >
      {/* First Part - Logo, Description, Contact Info */}
      <Box flex="1">
        <Image src={logo} alt="Keats AI - An AI Assistant for Presales Teams" mb={0} maxW={"100px"} />
        <Text mb={4} fontSize={"md"}>
          With Keats, you spend less time on manual tasks and more time understanding client needs and building stronger relationships. It tracks RFP requirements, updates proposals in real-time, and even answers client questions—so you can focus on what truly matters: winning deals and driving revenue growth.
        </Text>
        <Link
            href={"https://wa.link/1usn6f"}
            mb={6}
            isExternal
          >
            <Text> Phone: +91-93 5383 7677 </Text>
          </Link>
          <Link
            href="mailto:support@nearyou.co.in"
            mb={2}
            isExternal
          >
            <Text> Email: support@nearyou.co.in </Text>
          </Link>
      </Box>

      {/* Second Part - Links and Address */}
      <Flex flex="1" direction={{ base: "column", md: "row" }} gap={16} justifyContent={"flex-end"}>
        {/* Links */}
        <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
          <Text mb={4} fontWeight={"bold"}>Sitemap</Text>
          <Link
            onClick={() => {
              document.getElementById('automate-section').scrollIntoView({ behavior: 'smooth' });
            }}
          >
            <Text> Products </Text>
          </Link>
          <Link
            onClick={() => {
              document.getElementById('partner-section').scrollIntoView({ behavior: 'smooth' });
            }}
          >
            <Text>Solution</Text>
          </Link>
          <Link
            onClick={() => {
              document.getElementById('benefit-section').scrollIntoView({ behavior: 'smooth' });
            }}
          >
            <Text>Why Keats</Text>
          </Link>
        </Box>

        {/* Address */}
        <Box>
          <Text fontWeight="bold" mb={4}>India</Text>
          <Text>
            Survey Number 3, Aishwarya Complex <br />
            GoSpaze, Sarjapur Main Road <br />
            Bengaluru, Karnataka 560103
          </Text>
        </Box>
      </Flex>
    </Flex>

    {/* Bottom Center - Terms & Conditions */}
    <Divider my={4} borderColor="whiteAlpha.600" />
    <Flex justify="center" mb={4}>
      <Link href="/privacy" mx={2} _hover={{ textDecoration: "underline" }}>
        Terms & Conditions
      </Link>
      <Text mx={2}>•</Text>
      <Link href="/privacy" mx={2} _hover={{ textDecoration: "underline" }}>
        Privacy Policy
      </Link>
    </Flex>
  </Box>
);

export default Footer;