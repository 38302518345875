import { useState } from "react";
import { Box, Flex, Heading, Text, Input, Button, Image, useToast, FormControl, FormErrorMessage } from "@chakra-ui/react";
import axios from "../../utils/axios";
import contact from '../../images/contact.png'

const ContactSection = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const toast = useToast();
  
  const handleSubmit = async () => {
    setError("");  // Reset error before validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      setError("Email is required.");
      return;
    }
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      // API call to store email data
      await axios.post("/api/save-lead/v0", { email });
      setEmail("");  // Clear the email field on success
      toast({
        title: "Thanks for contacting Keats. Our team will contact you soon.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("API error:", error);
      toast({
        title: "Your email could not be saved. Please try again!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
  <Box
    id="contact-section"
    bg="secondary.500"      // Background color from theme
    width={{ base: "90%", md: "90%" }}             // Width as specified
    borderRadius="16px"     // Border radius
    py={10}
    px={6}
    my={4}
    mx="auto"               // Center horizontally
    textAlign="center"
  >
    {/* Image at the Top */}
    <Image 
      src={contact}
      alt="Contact Keats AI - RFP Automation Platform" 
      mb={4}                 // Spacing below image
      mx="auto"              // Center image horizontally
      maxH="50px"           // Set max height as needed
    />

    {/* Heading and Subheading */}
    <Heading size={{ base: "xl", md: "2xl" }} color="white" mb={2}>
      Still have questions?
    </Heading>
    <Text fontSize="lg" color="white" mb={8} opacity="0.88">
      Can't find answers to what you are looking for? Enter your email address and we will get in touch with you ASAP
    </Text>

    {/* Email Input and Button */}
    <FormControl isInvalid={!!error} maxW="600px" mx="auto">
    <Flex 
      maxW="600px"           // Max width for input and button container
      mx="auto" 
      align="center" 
      gap={4}                // Space between input and button
      justifyContent={"flex-start"}
      alignItems={{ base: "center", md: "flex-start" }}
      flexDir={{ base: "column", md: "row" }}
    >
        <Box width="100%">
          <Input 
            value={email}
            placeholder="Enter your email here" 
            bg="rgba(255, 255, 255, 0.88)"
            _placeholder={{ color: "secondary.500" }}
            color="secondary.500"
            flex="1"             // Allow input to take up available space
            onChange={(e) => setEmail(e.target.value)}
          />
          {error && <FormErrorMessage color="white">{error}</FormErrorMessage>}
        </Box>
        <Button 
          bg="white" 
          color="secondary.500"
          _hover={{ bg: "gray.100" }}  // Hover effect for button
          borderRadius="md"
          onClick={handleSubmit}
          isDisabled={!email}
        >
          Submit
        </Button>
      </Flex>
    </FormControl>
  </Box>
)};

export default ContactSection;