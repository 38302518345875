import { Box, Flex, Text, Link, Divider, Image, Heading } from "@chakra-ui/react";
import logo from '../images/keatsai-128.png'
import Footer from "../components/Footer";
import Header from '../components/header';

const Privacy = () => (
  <Box width="100%" alignItems={"center"} display={"flex"} flexDir={"column"}>
    <Header />
    <Flex 
      maxW="1206px"       // Centered width limit
      mx="auto"           // Center horizontally
      direction={{ base: "column", md: "row" }}
      justify="space-between"
      gap={8}
      pt={8}
    >
      {/* First Part - Logo, Description, Contact Info */}
      <Box flex="1">
        {/* <Image src={logo} alt="Keats AI - An AI Assistant for Presales Teams" mb={0} maxW={"100px"} /> */}
        <Heading my={4}> Privacy Policy </Heading>
        <Box my={4}>
            <Text fontSize={"3xl"} fontWeight={"bold"}>About Us:</Text>
            <Text mb={4} fontSize={"md"}>
            With Keats, you spend less time on manual tasks and more time understanding client needs and building stronger relationships. It tracks RFP requirements, updates proposals in real-time, and even answers client questions—so you can focus on what truly matters: winning deals and driving revenue growth.
            </Text>
        </Box>
        <Box my={4}>
        <   Text fontSize={"3xl"} fontWeight={"bold"}>Overview:</Text>
            <Text mb={4} fontSize={"md"}>
                Last updated on September 30, 2024 / Effective from September 30, 2024
                We at Keats AI(the “Company”, “we”, or “us”) are committed to safeguarding
                the privacy of our customers. This Privacy Policy entails the way in which your
                Personal Information or Usage Information will be collected, used, shared,
                stored, protected or disclosed by our service (including https://keatsai.in). This
                Privacy Policy applies to the mobile application or website developed by us in
                relation to services provided to you by the Company from time to time
                (“Services”). By using our Services, you agree to be bound by the terms of this
                Privacy Policy. If you do not accept the terms of the Privacy Policy, you are
                directed to discontinue accessing/using our application in any way. We strongly
                recommend that you read the policy carefully before proceeding with any
                transaction on our application.            
            </Text>
        </Box>

        <Box my={4}>
            <Text fontSize={"3xl"} fontWeight={"bold"}>Information We Collect</Text>
            <Text mb={4} fontSize={"md"}>
                We collect information about you for the following general purposes: For
                registration and to manage your account, including to facilitate your access to
                and use of our Services; to communicate with you in general, including to
                provide information about us; to enable us to publish your reviews, forum posts,
                and other content; to respond to your questions and comments, to prevent
                potentially prohibited or illegal activities and to enforce our Terms of Use.
            </Text>
            <Text fontSize={"xl"} fontWeight={"bold"}>Collecting and Using Your Personal Data</Text>
            <Text mb={4} fontSize={"md"}>
                When you visit or use our Services, we collect information about your computer
                or mobile device. Some of the information we collect include:
            </Text>
            <Text fontSize={"xl"} fontWeight={"bold"}>Personal Data</Text>
            <Text mb={4} fontSize={"md"}>
                While using Our Service, We may ask You to provide Us with certain personally
                identifiable information that can be used to contact or identify You. Personally
                identifiable information may include, but is not limited to: <br />
                1. Email address <br />
                2. First name and last name <br />
                3. Gmail Profile Photo Link <br />
            </Text>
        </Box>
        <Box my={4}>
            <Text fontSize={"3xl"} fontWeight={"bold"}>How we use your information</Text>
            <Text mb={4} fontSize={"md"}>
                The Company may use Personal Data for the following purposes: <br />
                To provide and maintain our Service, including to monitor the usage of our
                Service. <br />
                To manage Your Account: to manage Your registration as a user of the Service.
                The Personal Data You provide can give You access to different functionalities
                of the Service that are available to You as a registered user. <br />
                For the performance of a contract: the development, compliance and
                undertaking of the purchase contract for the products, items or services You
                have purchased or of any other contract with Us through the Service. <br />
                To contact You: To contact You by email or other equivalent forms of electronic
                communication, such as a mobile application's push notifications regarding
                updates or informative communications related to the functionalities, products
                or contracted services, including the security updates, when necessary or
                reasonable for their implementation. <br />
                To provide You with news, special offers and general information about other
                goods, services and events which we offer that are similar to those that you
                have already purchased or enquired about unless You have opted not to
                receive such information. <br />
                To manage Your requests: To attend and manage Your requests to Us.<br />
                To deliver targeted advertising to You: We may use Your information to develop
                and display content and advertising (and work with third-party vendors who do
                so) tailored to Your interests and/or location and to measure its effectiveness.
                For other purposes: We may use Your information for other purposes, such as
                data analysis, identifying usage trends, determining the effectiveness of our
                promotional campaigns and to evaluate and improve our Service, products,
                services, marketing and your experience.
            </Text>
        </Box>


        <Box my={4}>
            <Text fontSize={"xl"} fontWeight={"bold"}>With whom we share your information?</Text>
            <Text mb={4} fontSize={"md"}>
                <Text as="span" fontWeight={"bold"}> We neither rent nor sell your Information </Text> in personally identifiable form to
                anyone in order to monetize through ads or leads. However, we do share your
                Personal Information with third parties as described below: <br />
                Third-party vendors: We employ third party vendors to provide Services on our
                behalf for varied purposes like business analytics, customer service, marketing
                etc. We may authorize such vendors to collect information on our behalf in
                order to operate features of our application or to deliver services tailored to
                your needs. We assure that such information used by third party vendors is
                only to perform their functions and they are not permitted to share or use that
                information for any other purpose. <br />
                Companies within our corporate family: We may be required to provide
                information about you to our parent or subsidiary company or corporate
                affiliates. To the extent our parent company or subsidiaries or corporate
                affiliates have information about you, they will follow the best practices as
                described in this Privacy Policy when using it. <br />
                Business Partners: We take the help of our Business Partners in conjunction
                with whom we offer our Services to you. If you choose to access these optional
                services provided by our Business Partners, we may share information about
                you, including your personal information, with those Partners. Please note that
                we do not control the privacy practices of these third-party Business Partners.
                Affiliated Websites: If you were referred to us from another website, we may
                have to share information pertaining to registration information including but
                not limited to name, e mail address, phone numbers etc. In such instances we
                encourage you to read and review their Privacy Policy of the website that
                referred you to us. We will not be responsible for the handling of such
                information by them. <br />
                Business Transfers: We may choose to buy or sell assets. In these types of
                transactions, customer information is typically one of the business assets that
                would be transferred. Also, if we (or our assets) are acquired, or if we go out of
                business, enter bankruptcy, or go through some other change of control,
                Personal Information would be one of the assets transferred to or acquired by a
                third party. <br />
                Government Bodies: We may disclose your Personal Information and Usage
                Information as and when required in response to a sub-poena, court order,
                government demand or to comply with any legal requirements for protecting
                our interest. We will contact you by e-mail when we have to adhere to such
                legal request. All communications sent by us at such email address shall be
                deemed to have been communicated to you.
            </Text>
        </Box>
        <Box my={4}>
            <Text fontSize={"xl"} fontWeight={"bold"}>Retention of Your Personal Data</Text>
            <Text mb={4} fontSize={"md"}>
                The Company will retain Your Personal Data only for as long as is necessary for
                the purposes set out in this Privacy Policy. We will retain and use Your Personal
                Data to the extent necessary to comply with our legal obligations (for example,
                if we are required to retain your data to comply with applicable laws), resolve
                disputes, and enforce our legal agreements and policies.
            </Text>
        </Box>

        <Box my={4}>
            <Text fontSize={"3xl"} fontWeight={"bold"}>How do we protect your information</Text>
            <Text mb={4} fontSize={"md"}>
                We want you to have full faith in having your information safe and secure with
                us. We follow generally accepted standards to protect the Personal Information
                and Usage Information submitted to us. While no Services can guarantee fullproof security, we have appropriate administrative, technical and security
                procedures to keep your information intact, secure and confidential. Your
                information is handled only for the purposes required by authorized employees
                or agents or third party vendors and the like and we employ appropriate
                firewalls, encryption technologies and intrusion detection systems to keep your
                information secure and safe from any unauthorized use.
            </Text>
            <Text fontSize={"xl"} fontWeight={"bold"}>What happens when you use our Services from outside your region?</Text>
            <Text mb={4} fontSize={"md"}>
                If you are accessing our application from outside the India, please be mindful
                that such information may be transferred, stored or processed in the India
                where our servers or central databases are located. Even though you access it
                from a foreign land we ensure we will follow the best practices in keeping your
                information protected. By using our Services you understand that we are
                governed by the Indian Privacy and Data Protection Laws which are very
                different from the laws of your country. <br />
                The Company will take all steps reasonably necessary to ensure that Your data
                is treated securely and in accordance with this Privacy Policy and no transfer of
                Your Personal Data will take place to an organization or a country unless there
                are adequate controls in place including the security of Your data and other
                personal information.
            </Text>
            <br />
            <Text fontSize={"xl"} fontWeight={"bold"}>Email Marketing</Text>
            <Text mb={4} fontSize={"md"}>
                We may use Your Personal Data to contact You with newsletters, marketing or
                promotional materials and other information that may be of interest to You. You
                may opt-out of receiving any, or all, of these communications from Us by
                following the unsubscribe link or instructions provided in any email We send or
                by contacting us. <br />
                Here's Amazon SES's privacy policy https://aws.amazon.com/cn/privacy/
            </Text>
        </Box>
        <Box my={4}>
            <Text fontSize={"3xl"} fontWeight={"bold"}>GDPR Privacy</Text>
            <Text fontSize={"xl"} fontWeight={"bold"}>Legal Basis for Processing Personal Data under GDPR</Text>
            <Text mb={4} fontSize={"md"}>
                We may process Personal Data under the following conditions: <br />
                Consent: You have given Your consent for processing Personal Data for one or
                more specific purposes. Performance of a contract: Provision of Personal Data
                is necessary for the performance of an agreement with You and/or for any precontractual obligations thereof. <br />
                Legal obligations: Processing Personal Data is necessary for compliance with a
                legal obligation to which the Company is subject. <br />
                Vital interests: Processing Personal Data is necessary in order to protect Your
                vital interests or of another natural person. <br />
                Public interests: Processing Personal Data is related to a task that is carried out
                in the public interest or in the exercise of official authority vested in the
                Company. <br />
                Legitimate interests: Processing Personal Data is necessary for the purposes of
                the legitimate interests pursued by the Company. <br />
                In any case, the Company will gladly help to clarify the specific legal basis that
                applies to the processing, and in particular whether the provision of Personal
                Data is a statutory or contractual requirement, or a requirement necessary to
                enter into a contract.
            </Text>

            <Text fontSize={"xl"} fontWeight={"bold"}>Your Rights under the GDPR</Text>
            <Text mb={4} fontSize={"md"}>
                The Company undertakes to respect the confidentiality of Your Personal Data
                and to guarantee You can exercise Your rights. <br />
                You have the right under this Privacy Policy, and by law if You are within the EU,
                to: <br />
                Request access to Your Personal Data. The right to access, update or delete
                the information We have on You. Whenever made possible, you can access,
                update or request deletion of Your Personal Data directly within Your account
                settings section. If you are unable to perform these actions yourself, please
                contact Us to assist You. This also enables You to receive a copy of the
                Personal Data We hold about You. Request correction of the Personal Data that
                We hold about You. You have the right to to have any incomplete or inaccurate
                information We hold about You corrected. <br />
                Object to processing of Your Personal Data. This right exists where We are
                relying on a legitimate interest as the legal basis for Our processing and there is
                something about Your particular situation, which makes You want to object to
                our processing of Your Personal Data on this ground. You also have the right to
                object where We are processing Your Personal Data for direct marketing
                purposes. Request erasure of Your Personal Data. You have the right to ask Us
                to delete or remove Personal Data when there is no good reason for Us to
                continue processing it. <br />
                Request the transfer of Your Personal Data. We will provide to You, or to a thirdparty You have chosen, Your Personal Data in a structured, commonly used,
                machine-readable format. Please note that this right only applies to automated
                information which You initially provided consent for Us to use or where We
                used the information to perform a contract with You. <br />
                Withdraw Your consent. You have the right to withdraw Your consent on using
                your Personal Data. If You withdraw Your consent, We may not be able to
                provide You with access to certain specific functionalities of the Service.
            </Text>
        </Box>

        <Box my={4}>
            <Text fontSize={"3xl"} fontWeight={"bold"}>CCPA Privacy</Text>
            <Text mb={4} fontSize={"md"}>
                This privacy notice section for California residents supplements the information
                contained in Our Privacy Policy and it applies solely to all visitors, users, and
                others who reside in the State of California.
            </Text>
            <Text fontSize={"xl"} fontWeight={"bold"}>Sources of Personal Information</Text>
            <Text mb={4} fontSize={"md"}>
                We obtain the categories of personal information listed above from the
                following categories of sources: <br />
                Directly from You. For example, from the forms You complete on our Service,
                preferences You express or provide through our Service, or from Your
                purchases on our Service. <br />
                Indirectly from You. For example, from observing Your activity on our Service. <br />
                Automatically from You. For example, through cookies We or our Service
                Providers set on Your Device as You navigate through our Service. <br />
                From Service Providers. For example, third-party vendors to monitor and
                analyze the use of our Service, third-party vendors to provide advertising on
                our Service, third-party vendors to deliver targeted advertising to You, thirdparty vendors for payment processing, or other third-party vendors that We
                use to provide the Service to You.
            </Text>

            <Text fontSize={"xl"} fontWeight={"bold"}>Use of Personal Information for Business Purposes or Commercial Purposes</Text>
            <Text mb={4} fontSize={"md"}>
                We may use or disclose personal information We collect for "business
                purposes" or "commercial purposes" (as defined under the CCPA), which may
                include the following examples: <br />
                To operate our Service and provide You with our Service. <br />
                To provide You with support and to respond to Your inquiries, including to
                investigate and address Your concerns and monitor and improve our Service. <br />
                To fulfill or meet the reason You provided the information. For example, if You
                share Your contact information to ask a question about our Service, We will use
                that personal information to respond to Your inquiry. If You provide Your
                personal information to purchase a product or service, We will use that
                information to process Your payment and facilitate delivery. <br />
                To respond to law enforcement requests and as required by applicable law,
                court order, or governmental regulations. <br />
                As described to You when collecting Your personal information or as otherwise
                set forth in the CCPA. For internal administrative and auditing purposes.
                To detect security incidents and protect against malicious, deceptive,
                fraudulent or illegal activity, including, when necessary, to prosecute those
                responsible for such activities. <br />
                Please note that the examples provided above are illustrative and not intended
                to be exhaustive. For more details on how we use this information, please refer
                to the "Use of Your Personal Data" section. If We decide to collect additional
                categories of personal information or use the personal information We
                collected for materially different, unrelated, or incompatible purposes We will
                update this Privacy Policy.
            </Text>

            <Text fontSize={"xl"} fontWeight={"bold"}>Personal Information of Minors</Text>
            <Text mb={4} fontSize={"md"}>
                If You have reason to believe that a child under the age of 13 (or 16) has
                provided Us with personal information, please contact Us with sufficient detail
                to enable Us to delete that information.
            </Text>

            <Text fontSize={"xl"} fontWeight={"bold"}>Your Rights under the CCPA</Text>
            <Text mb={4} fontSize={"md"}>
                The CCPA provides California residents with specific rights regarding their
                personal information. If You are a resident of California, You have the following
                rights: <br />
                The right to notice. You have the right to be notified which categories of
                Personal Data are being collected and the purposes for which the Personal
                Data is being used. <br />
                The right to request. Under CCPA, You have the right to request that We
                disclose information to You about Our collection, use, sale, disclosure for
                business purposes and share of personal information. <br />
                Once We receive and confirm Your request, We will disclose to You: <br />
                The categories of personal information We collected about You. <br />
                The categories of sources for the personal information We collected about You.
                Our business or commercial purpose for collecting or selling that personal
                information. <br />
                The categories of third parties with whom We share that personal information.
                The specific pieces of personal information We collected about You. <br />
                If we sold Your personal information or disclosed Your personal information for
                a business purpose, We will disclose to You: <br />
                The categories of personal information categories sold. <br />
                The categories of personal information categories disclosed. <br />
                The right to say no to the sale of Personal Data (opt-out). You have the right to
                direct Us to not sell Your personal information. To submit an opt-out request
                please contact Us. <br />
                The right to delete Personal Data. You have the right to request the deletion of
                Your Personal Data, subject to certain exceptions. Once We receive and
                confirm Your request, We will delete (and direct Our Service Providers to
                delete) Your personal information from our records, unless an exception
                applies. We may deny Your deletion request if retaining the information is
                necessary for Us or Our Service Providers to: <br />
                Complete the transaction for which We collected the personal information,
                provide a good or service that You requested, take actions reasonably
                anticipated within the context of our ongoing business relationship with You, or
                otherwise perform our contract with You. <br />
                Detect security incidents, protect against malicious, deceptive, fraudulent, or
                illegal activity, or prosecute those responsible for such activities.
                Debug products to identify and repair errors that impair existing intended
                functionality. <br />
                Exercise free speech, ensure the right of another consumer to exercise their
                free speech rights, or exercise another right provided for by law.
                Comply with the California Electronic Communications Privacy Act (Cal. Penal
                Code § 1546 et. seq.). <br />
                Engage in public or peer-reviewed scientific, historical, or statistical research in
                the public interest that adheres to all other applicable ethics and privacy laws,
                when the information's deletion may likely render impossible or seriously impair
                the research's achievement, if You previously provided informed consent. <br />
                Enable solely internal uses that are reasonably aligned with consumer
                expectations based on Your relationship with Us. <br />
                Comply with a legal obligation. <br />
                Make other internal and lawful uses of that information that are compatible with
                the context in which You provided it. <br />
                The right not to be discriminated against. You have the right not to be
                discriminated against for exercising any of Your consumer's rights, including
                by: <br />
                Denying goods or services to You. <br />
                Charging different prices or rates for goods or services, including the use of
                discounts or other benefits or imposing penalties. <br />
                Providing a different level or quality of goods or services to You. <br />
                Suggesting that You will receive a different price or rate for goods or services
                or a different level or quality of goods or services.
            </Text>

            <Text fontSize={"xl"} fontWeight={"bold"}>Exercising Your CCPA Data Protection Rights</Text>
            <Text mb={4} fontSize={"md"}>
                In order to exercise any of Your rights under the CCPA, and if You are a
                California resident, You can contact us by email: garg.sachin1809@gmail.com
            </Text>
        </Box>

        <Box my={4}>
            <Text fontSize={"3xl"} fontWeight={"bold"}>Links to Other Websites</Text>
            <Text mb={4} fontSize={"md"}>
                Our Service may contain links to other websites that are not operated by Us. If
                You click on a third party link, You will be directed to that third party's site. We
                strongly advise You to review the Privacy Policy of every site You visit. <br />
                We have no control over and assume no responsibility for the content, privacy
                policies or practices of any third party sites or services.
            </Text>
        </Box>

        <Box my={4}>
            <Text fontSize={"3xl"} fontWeight={"bold"}>Changes to this Privacy Policy</Text>
            <Text mb={4} fontSize={"md"}>
                We may update Our Privacy Policy from time to time. We will notify You of any
                changes by posting the new Privacy Policy on this page. <br />
                We will let You know via email and/or a prominent notice on Our Service, prior
                to the change becoming effective and update the "Last updated" date at the
                top of this Privacy Policy. <br />
                You are advised to review this Privacy Policy periodically for any changes.
                Changes to this Privacy Policy are effective when they are posted on this page.
            </Text>
        </Box>

        <Box my={4}>
            <Text fontSize={"3xl"} fontWeight={"bold"}>Contact Us</Text>
            <Text mb={4} fontSize={"md"}>
                If you have any questions about this Privacy Policy, You can contact us at
                support: garg.sachin1809@gmail.com
            </Text>
        </Box>

      </Box>
    </Flex>
    <Footer />
  </Box>
);

export default Privacy;