import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import salesforce from '../../images/salesforce.png'
import pipedrive from '../../images/pipedrive.png'
import gmail from '../../images/gmail.png'
import teams from '../../images/teams.png'
import excel from '../../images/excel.png'
import hubspot from '../../images/hubspot.png'
import slack from '../../images/slack.png'
import spreadsheet from '../../images/spreadsheet.png'


const IntegrationSection = () => (
  <Box
    bg="secondary.500"  // Background color from the theme
    borderRadius="16px"  // Border radius
    py={{ base: 4, md: 8 }}              // Padding for top and bottom
    px={{ base: 4, md: 6 }}             // Padding for sides
    textAlign="center"
    mx="auto"            // Centered horizontally
    width= {{ base: "90%", md: "90%" }} 
  >
    {/* Heading */}
    <Heading size="xl" color="white" mb={{ base: 4, md: 8 }} >
      Integrate with your favourite tools
    </Heading>

    {/* Logos Row */}
    <Flex 
      wrap="wrap"          // Wrap logos if they exceed container width
      justify="center"     // Center logos within the container
      align="center"
      gap={3}              // Space between logos
      mb={4}               // Margin bottom for spacing before "and more..."
    >
      {/* Example logos */}
      <Image src={salesforce} alt="Keats AI - Salesforce" height={{ base: "70px", md: "100px" }} />
      <Image src={hubspot} alt="Keats AI - Hubspot" height={{ base: "70px", md: "100px" }} />
      <Image src={pipedrive} alt="Keats AI - Pipedrive" height={{ base: "70px", md: "100px" }} />
      <Image src={gmail} alt="Keats AI - Gmail" height={{ base: "70px", md: "100px" }} />
      <Image src={spreadsheet} alt="Keats AI - Spreadsheet" height={{ base: "70px", md: "100px" }} />
      <Image src={excel} alt="Keats AI - Excel" height={{ base: "70px", md: "100px" }} />
      <Image src={teams} alt="Keats AI - MS Teams" height={{ base: "70px", md: "100px" }} />
      <Image src={slack} alt="Keats AI - Slack" height={{ base: "70px", md: "100px" }} />
    </Flex>

    <Text fontWeight="bold" color="white" fontSize={"lg"}>
      and more...
    </Text>
  </Box>
);

export default IntegrationSection;
