import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    primary: {
      100: '#ffe5e6', // #fde0e0 one more lighter shade of pastel red, #ffd5d4
      500: '#ff6961',  // pastel red color.
    },
    secondary: {
      100: '#ddf2ff',
      500: '#95554f', // #95554f brownish #7c3b44 different brownish
      // #77dd76 pastel green, # lighter pastel green E7F1E8, #BDE7BD mid light pastel green
    },
    tertiary: {
      100: '#ddf2ff',
      500: '#1b4975', // #1b4975 bluish #ddf2ff light shade of bluish
      // #77dd76 pastel green, # lighter pastel green E7F1E8, #BDE7BD mid light pastel green
    },
  },
  fonts: {
    heading: "'Mulish', sans-serif",
    body: "'Mulish', sans-serif",
  },
  components: {
    Drawer: {
      parts: ['dialog', 'header', 'body'],
      variants: {
        primary: {
        secondary: {
          dialog: {
            maxW: "30vw",
            
          }
        }
      },  
    }}},
});

export default theme;