import { Box, Flex, Button, Link, Text, Image, Menu, MenuButton, MenuItem, MenuList, IconButton } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import keatsLogo from "../images/keatsai-128.png";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link as RouterLink, useLocation } from "react-router-dom";


function Header () {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

  const handleHomeClick = () => {
    if (location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

   // Update header style on scroll
   useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);  // Adjust threshold as needed
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
return (

    <Box bg={isScrolled ? "rgba(255, 255, 255, 0.8)" : "white"}
      py={isScrolled ? 4 : 4} px={4}
      width="100%"
      position={isScrolled ? "fixed" : "static"}  // Fixed only when scrolled
      zIndex="10000"                               // Keeps header on top
      boxShadow={isScrolled ? "md" : "none"}      // Adds shadow when scrolled
      backdropFilter={isScrolled ? "blur(10px)" : "none"}  // Adds blur when scrolled
      transition="all 0.3s ease"                  // Smooth transition effect
      maxW="1200px"
      borderRadius={isScrolled ? "8px" : "none"}  // Adds blur when scrolled
    >
    <Flex justify="space-between" align="center" mx="auto"
    >
      <Link as={RouterLink} to="/" onClick={handleHomeClick} _hover={{ textDecoration: "none" }}>
        <Flex align="center">
          {/* Chakra UI Image Component with defined height */}
          <Image 
            src={keatsLogo} 
            alt="Keats AI - An AI Assitant for Presales Team" 
            height="40px" // Define the logo height
            mr={2} // Margin to create space between logo and text
          />
          <Text color="primary.500" fontWeight="bold" fontSize="2xl">
            Keats AI
          </Text>
        </Flex>
      </Link>
      
      <Flex align="center" display={{ base: "none", md: "flex" }}>
        <Link mx={4}
          onClick={() => {
            document.getElementById('automate-section').scrollIntoView({ behavior: 'smooth' });
          }}
        >
          <Text fontWeight="bold">Products</Text>
        </Link>
        <Link mx={4}
          onClick={() => {
            document.getElementById('partner-section').scrollIntoView({ behavior: 'smooth' });
          }}
        >
          <Text fontWeight="bold">Solution</Text>
        </Link>
        <Link mx={4}
          onClick={() => {
            document.getElementById('benefit-section').scrollIntoView({ behavior: 'smooth' });
          }}
        >
          <Text fontWeight="bold">Why Keats</Text>
        </Link>
      </Flex>
        <Flex align="center">
          <Box display={{ base: "none", md: "block" }} mx={4} >
            <Link href="https://wa.link/1usn6f" isExternal>
              <Text fontWeight="bold">+91-9353837677</Text>
            </Link>
          </Box>
          <Button colorScheme={"primary"} ml={4} size="md" width="144px"
                boxShadow="md"  // Adds a large shadow to the button
                onClick={() => {
                  document.getElementById('contact-section').scrollIntoView({ behavior: 'smooth' });
                }}
          >
            Contact Us
          </Button>
        </Flex>
        {/* Hamburger Menu for Mobile */}
        <Box display={{ base: "block", md: "none" }}>
          <Menu>
            <MenuButton as={IconButton} icon={<HamburgerIcon />} variant="outline" />
            <MenuList>
              <MenuItem onClick={() => document.getElementById('automate-section').scrollIntoView({ behavior: 'smooth' })}>
                Products
              </MenuItem>
              <MenuItem onClick={() => document.getElementById('partner-section').scrollIntoView({ behavior: 'smooth' })}>
                Solution
              </MenuItem>
              <MenuItem onClick={() => document.getElementById('benefit-section').scrollIntoView({ behavior: 'smooth' })}>
                Why Keats
              </MenuItem>
              <MenuItem as="a" href="https://wa.link/1usn6f" isExternal>
                +91-9353837677
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
    </Flex>
  </Box>
)
}

export default Header;