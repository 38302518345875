import { Box, Flex, Heading, Text, List, ListItem, ListIcon, Icon, Image } from "@chakra-ui/react";
import { LockIcon, CheckCircleIcon } from "@chakra-ui/icons";
import shield from '../../images/shield.png'
import { ArrowForwardIcon } from "@chakra-ui/icons";

const SecuritySection = () => (
  <Box
    bg="secondary.500"    // Background color from theme
    mt={8}
    py={8}
    px={6}
    width="100%"            // Width as specified
    mx="auto"              // Center horizontally
    textAlign="center"
    display={"flex"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <Box maxW="1200px" width={{ base: "100%", md: "83.75%" }}>
    {/* Section Heading */}
    <Heading size={{ base: "xl", md: "2xl" }} color="white" mb={8}>
      Security is at the Heart of Keats
    </Heading>

    <Flex
      direction={{ base: "column", md: "row" }}  // Stack on mobile, side-by-side on desktop
      align="center"
      justify="space-between"
      gap={{ base: 4, md: 8 }}                  // Space between left and right sections
    >
      {/* Left Side - 35% Box with Icon, Heading, and Text */}
      <Box 
        flex="0.4"
        bg="white" 
        borderRadius="16px" 
        px={{ base: 4, md: 8 }}
        py={{ base: 6, md: 8 }}
        textAlign="left"
      >
        <Flex align="center" mb={4}>
          <Image 
            src={shield}  // Path to your custom icon image
            boxSize="36px"                       // Size of the image to match icon size
            mr={3}                               // Margin-right to separate image from heading
          />          
          <Heading size="lg" color="gray.800">
            Trusted & Secure AI
          </Heading>
        </Flex>
        <Text color="gray.600" fontSize="18px">
          Work smarter with Keats AI's trusted approach to AI, designed for enterprises with the most stringent security requirements.
        </Text>
      </Box>

      {/* Right Side - 3 Bullet Points */}
      <Box flex="0.6" textAlign="center">
        <List spacing={4} color="white" display="inline-block" textAlign="left">
          <ListItem fontSize="18px">
            {/* <ListIcon as={CheckCircleIcon} color="orange.500" /> */}
            <ArrowForwardIcon color="white" mr={2} />
              <Text as="span" fontWeight={"bold"}> Flexible Data Hosting: </Text> Your data is hosted in compliance with regional laws, ensuring full alignment with your local regulations.
          </ListItem>
          <ListItem fontSize="18px">
            {/* <ListIcon as={CheckCircleIcon} color="orange.500" /> */}
            <ArrowForwardIcon color="white" mr={2} />
            <Text as="span" fontWeight={"bold"}> Privacy Assured: </Text> Your data remains exclusively yours, never used to train base models or shared with external parties.
          </ListItem>
          <ListItem fontSize="18px">
            {/* <ListIcon as={CheckCircleIcon} color="orange.500" /> */}
            <ArrowForwardIcon color="white" mr={2} />
              <Text as="span" fontWeight={"bold"}> Commitment to Compliance: </Text> Keats AI upholds rigorous industry standards and data privacy practices to protect and responsibly manage your information.
          </ListItem>
        </List>
      </Box>
    </Flex>
    </Box>
  </Box>
);

export default SecuritySection;
