import { Box, Flex, Heading, Text, Image } from "@chakra-ui/react";
import feature_1 from '../../images/feature_1.png'
import feature_2 from '../../images/feature_2.png'
import feature_3 from '../../images/feature_3.png'

const features = [
  {
    title: "Go/No-Go Insights",
    description: "Keats helps you prioritize deals by generating deal summaries and critical insights for quick Go/No-Go decisions, saving hours of analysis",
    image: feature_1,
  },
  {
    title: "Knowledge on Demand",
    description: "Access all information from your knowledge base and past proposals with a simple chatbot interface whenever you need it",
    image: feature_2,
  },
  {
    title: "Collaborate Effectively",
    description: "Automatically create and assign tasks to the right team members. Keats ensures every step of the process is tracked",
    image: feature_3,
  },
];

const FeatureSection = () => (
  <Flex
    id="feature-section"
    mx="auto"
    py={4}
    px={4}
    wrap="wrap"
    gap={4}         // Space between cards
    justify="center"
  >
    {features.map((feature, index) => (
      <Box
        key={index}
        width={{ base: "100%", md: "30%" }} // Responsive width
        borderRadius="16px"
        boxShadow="0px 10px 12px rgba(106, 106, 106, 0.1)"  // Custom shadow
        overflow="hidden"           // Ensures border-radius applies to child elements
        bg="white"
      >
        {/* Upper Part with Background and Image */}
        <Box 
          bg="primary.100" 
          height="50%" 
          display="flex" 
          justifyContent="center" 
          alignItems="center"
          p={4}
        >
          <Image 
            src={feature.image} 
            alt={feature.title} 
            objectFit="contain" // Ensures image is contained
            maxH="75%"        // Adjust as needed
          />
        </Box>

        {/* Bottom Part with Heading and Subheading */}
        <Box height="50%" p={4} textAlign="center">
          <Heading size="md" color="gray.800" mb={2} fontWeight={"bold"}>
            {feature.title}
          </Heading>
          <Text color="gray.600" size='md'>{feature.description}</Text>
        </Box>
      </Box>
    ))}
  </Flex>
);

export default FeatureSection;
