import React, { useEffect, useState } from "react";
import { Box, Heading, Text, Button, Flex } from "@chakra-ui/react";

import Header from "../components/header";
import Hero from "../components/homepage/Hero";
import AutomateSection from '../components/homepage/AutomateSection';
import PartnerSection from '../components/homepage/PartnerSection';
import IntegrationSection from '../components/homepage/IntegrationSection';
import FeatureSection from "../components/homepage/FeatureSection";
import SecuritySection from "../components/homepage/SecuritySection";
import BenefitSection from "../components/homepage/BenefitSection";
import ContactSection from "../components/homepage/ContactSection";
import Footer from "../components/Footer";

// import "../styles/home.css";
// import "../styles/homeResponsive.css";

function Home() {
  return (
    <Box width="100%" alignItems={"center"} display={"flex"} flexDir={"column"}>
      <Box maxW="1200px" width={{ base: "100%", md: "83.75%" }}>
          <Header />
          <Hero />
          <PartnerSection />
          <IntegrationSection />
          <AutomateSection />
          <FeatureSection />
        </Box>
        <SecuritySection />
        <Box maxW="1200px" width={{ base: "100%", md: "83.75%" }} alignContent={"center"}>
          <BenefitSection />
          <ContactSection />
      </Box>
      <Footer />
    </Box>
  );
}
export default Home;